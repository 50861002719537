import React from "react";
import {Card, Col, Container, ListGroup, Row} from "react-bootstrap";
import {Image} from "react-bootstrap";
import {LinkContainer} from 'react-router-bootstrap';

import fun from '../assets/navigating.png';

const Home = () => {
    return (
        <Container
            className={'min-vh-100 min-vw-100'}
            style={{
                //backgroundColor: 'steelblue',
                paddingTop: 8,
            }}>
            <Container
                className={'min-vh-100'}
                style={{
                    //backgroundColor: 'aliceblue',
                    marginTop: 0,
                    borderRadius: 8,
                    paddingTop: 8,
                    paddingBottom: 16}}>
                <Row>
                    <Col
                        xs={11} sm={10} md={11} lg={9} xl={7} xxl={5}>
                        <h3
                        className={'text-center'}
                        style={{
                            paddingTop: 0,
                            fontStyle: 'italic',
                            color: 'darkslateblue',
                            textAlign: 'center',
                        }}>
                            Simply effective workouts
                        </h3>
                    </Col>
                </Row>
                <Row style={{marginTop: 8, marginBottom: 16}}>
≈                    <Col
                        xs={5} sm={5} md={4} lg={3} xl={3} xxl={3}>
                        <Image
                            style={{marginLeft: '5%'}}
                            width={'90%'}
                            rounded={true}
                            src={fun}
                            alt={'photo'}
                        />
                    </Col>
                    <Col xs={6} sm={5} md={5} lg={4} xl={3} xxl={2}>
                        <Card style={{
                            backgroundColor: 'orange',
                            marginBottom: 16,
                        }}>
                            <Card.Header as={'h5'}>What's new!</Card.Header>
                            <Card.Body style={{backgroundColor: 'aliceblue'}}>
                                <Card.Title>Circout-lite for iPhone</Card.Title>
                                <Card.Text>Beta testers wanted</Card.Text>
                                <LinkContainer to={'/BetaTesters'}>
                                    <Card.Link>learn more</Card.Link>
                                </LinkContainer>
                            </Card.Body>
                        </Card>
                        <Card
                            style={{
                                backgroundColor: 'orange',
                                marginBottom: 10,
                                marginTop: 0,
                        }}>
                            <Card.Header as={'h5'}>Promotes</Card.Header>
                            <ListGroup variant={'flush'}>
                                <ListGroup.Item
                                    style={{backgroundColor: 'aliceblue'}}
                                >
                                    Cardiovascular health
                                </ListGroup.Item>
                                <ListGroup.Item
                                    style={{backgroundColor: 'aliceblue'}}
                                >
                                    Muscular strength and endurance                                </ListGroup.Item>
                                <ListGroup.Item
                                    style={{backgroundColor: 'aliceblue'}}
                                >
                                    Increased insulin sensitivity
                                </ListGroup.Item>
                                <ListGroup.Item
                                    style={{backgroundColor: 'aliceblue'}}
                                >
                                    Personal satisfaction
                                </ListGroup.Item>
                                <ListGroup.Item
                                    style={{backgroundColor: 'aliceblue'}}
                                >
                                    <LinkContainer to={'/Support'}>
                                        <Card.Link>learn more</Card.Link>
                                    </LinkContainer>
                                </ListGroup.Item>
                            </ListGroup>
                        </Card>
                        {/*}
                        <Card style={{backgroundColor: 'orange'}}>
                            <Card.Header as={'h5'}>What's new!</Card.Header>
                            <Card.Body style={{backgroundColor: 'aliceblue'}}>
                                <Card.Title>Circout-lite for iPhone</Card.Title>
                                <Card.Text>Beta testers wanted</Card.Text>
                                <LinkContainer to={'/BetaTesters'}>
                                    <Card.Link>learn more</Card.Link>
                                </LinkContainer>
                            </Card.Body>
                        </Card>
                        */}
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default Home;

