import {Container} from "react-bootstrap";
import ScreenShot from "./ScreenShot";
import settings from "../../assets/settings.png";
import swiperemove from "../../assets/swiperemove.png";
import pressremove from "../../assets/pressremove.png";

const preface =
    ['The same techniques are used to remove workouts and exercises'];
const settingsText = ['Tap Settings in the tab menu then either Manage workouts or Manage exercises.'];
const swiperemoveText = ['Locate the workout or exercise you want to delete then swipe toward the left until the ' +
'trash icon is displayed. Tap the button to delete the item.']
const pressremoveText = ['Alternatively, press and hold the item until the confirmation appears.'];

const RemovingItems = () => {
    return (
        <Container style={{marginTop: 32}}>
            <h3
                style={{fontStyle: 'italic', color: 'darkslateblue'}}
                className={'text-center mt-1 mb-1'}>
                Removing workouts and exercises
            </h3>
            <p style={{color: 'darkslateblue'}} className={'mt-3'}>{preface}</p>
            <ScreenShot text={settingsText} image={settings} />
            <ScreenShot text={swiperemoveText} image={swiperemove} />
            <ScreenShot text={pressremoveText} image={pressremove} />
        </Container>

    );
};

export default RemovingItems;
