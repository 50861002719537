import {Container} from "react-bootstrap";
import ScreenShot from "./ScreenShot";
import settings from "../../assets/settings.png";
import addingexercises from "../../assets/addingexercises.png"
import manageexercises from "../../assets/manageexercises.png"

const preface =
    'Exercises can be added during the process of defining a workout. ' +
    'Alternatively, use this feature to add exercises proactively.';
const settingsText = ['Tap Settings in the tab menu then Manage exercises.'];

const AddingExercises = () => {
    return (
        <Container style={{marginTop: 32}}>
            <h3 style={{fontStyle: 'italic', color: 'darkslateblue'}}
                className={'text-center mt-1 mb-1'}>
                Adding exercises
            </h3>
            <p style={{color: 'darkslateblue'}} className={'mt-3'}>{preface}</p>
            <ScreenShot text={settingsText} image={settings} />
            <ScreenShot text={['Tap +New']} image={manageexercises} />
            <ScreenShot text={['Type the new exercise name']} image={addingexercises} />
        </Container>
    )
}

export default AddingExercises;
