import {Container} from "react-bootstrap";
import ScreenShot from "./ScreenShot";
import settings from "../../assets/settings.png";
import manageexercises from "../../assets/manageexercises.png";
import viewingexercise from "../../assets/viewingexercise.png";

const preface =
    ['Predefined exercises (not added by the user) are provided with a brief set of instructions.'];
const settingsText = ['Tap Settings in the tab menu then Manage exercises.'];
const manageexercisesText = ['Tab any predefined exercise in the list.']

const ViewingExercise = () => {
    return (
        <Container style={{marginTop: 32}}>
            <h3
                style={{fontStyle: 'italic', color: 'darkslateblue'}}
                className={'text-center mt-1 mb-1'}>
                Viewing exercise instructions
            </h3>
            <p style={{color: 'darkslateblue'}} className={'mt-3'}>{preface}</p>
            <ScreenShot text={settingsText} image={settings} />
            <ScreenShot text={manageexercisesText} image={manageexercises} />
            <ScreenShot text={['Exercise instructions are displayed.']} image={viewingexercise} />
        </Container>

    );
};

export default ViewingExercise;
