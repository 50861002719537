import {Container} from "react-bootstrap";
import ScreenShot from "./ScreenShot";
import settings from "../../assets/settings.png";
import selectingexercises from"../../assets/selectingexercisesnew.png"
import addingworkout from "../../assets/addingworkout.png"
import SettingTiming from "./SettingTiming";

const preface =
    ['Use this feature to define and immediately save a new workout. ' +
    'Adding workouts is useful for defining one or more workouts proactively. '];
const settingsText = ['Tap Settings in the tab menu then Manage workouts.'];
const selectingexercisesText =
    ['Select existing exercises by tapping on the item in the Exercises list. ' +
    'The selected exercise with move into the Circuit list. ' +
    'Tap on the item in the Circuit list to remove the exercise. ' +
    'Exercises will be performed in the order they appear in the Circuit list. ' +
    'Tap clear to empty the circuit list and start over. ',
        'Add a new exercise by typing the name into the entry at the top of the page. ' +
        'The new exercise will be added to the Circuit list and will also be available for future use. ',
        'There is a three exercise minimum and an exercise can only be added once to a circuit. ' +
        'Tap Done to save the workout. '];

const AddingWorkout = () => {
    return (
        <Container style={{marginTop: 32}}>
            <h3
                style={{fontStyle: 'italic', color: 'darkslateblue'}}
                className={'text-center mt-1 mb-1'}>
                Adding workouts
            </h3>
            <p style={{color: 'darkslateblue'}} className={'mt-3'}>{preface}</p>
            <ScreenShot text={settingsText} image={settings} />
            <ScreenShot text={['Tap +New and then enter workout name. ']} image={addingworkout} />
            <SettingTiming />
            <ScreenShot text={selectingexercisesText} image={selectingexercises} />
        </Container>
    )
}

export default AddingWorkout;
