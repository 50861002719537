import ScreenShot from "./ScreenShot";
import settingtiming from "../../assets/settingtiming.png";

const settingtimingText =
    ['Use the sliders to set workout timing parameters. ' +
    'Duration is the total workout time. ' +
    'Effort is the active phase of an exercise. ' +
    'Recovery is inactive phase between exercises used to rest and/or prepare for the next exercise. '];

const SettingTiming = () => {
    return (
        <ScreenShot text={settingtimingText} image={settingtiming} />
    )
};

export default SettingTiming;
