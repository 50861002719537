import {Container} from "react-bootstrap";
import {Link} from "react-router-dom";
const intro = 'Perform-RT is looking for beta testers for its iPhone fitness apps. ' +
    'If you have a general interest in fitness and a specific interest in circuit training, ' +
    'we would welcome your participation. ' +
    'From a privacy perspective, CircOut-lite is a self-contained app with no external connections. ';
const preparation =
    'Beta testing of iOS apps requires TestFlight. ' +
    'TestFlight is an Apple product that is available free ' +
    'from the App store.' +
    'TestFlight provides details concerning app features and testing goals. ' +
    'TestFlight allows the tester to provide valuable feedback to the developers. Suggestions are often ' +
    'incorporated into future releases. ' +
    'Those who make an effort to utilize the app and provide feedback will be offered future updates at no cost ' +
    'and with no advertising. '

const BetaTesters = () => {
    const products = <Link to={'/Products'}>Products</Link>
    const support = <Link to={'/Support'}>Support</Link>
    const link = '//testflight.apple.com/join/gz18xFHd';
    return (
        <Container
            className={'min-vh-100 min-vw-100'}>
            <Container
                style={{backgroundColor: "aliceblue",
                    marginTop: 24,
                    borderRadius: 8,
                    paddingTop: 16,
                    paddingBottom: 16}}>
                <h3 style={{color: 'darkslateblue'}} className={'text-center'}>Beta Testing</h3>
                <p style={{color: 'darkslateblue'}}>{intro}</p>
                <p style={{color: 'darkslateblue'}}>{preparation}</p>
                <p style={{color: 'darkslateblue'}}>A brief overview of this app is provided on the {products} page.
                    General information about circuit training and a detailed user guide
                    are provided on the {support} page.
                </p>
                <p style={{color: 'darkslateblue'}}>
                    Use the following link on your iOS device to install the beta version of CircOut-lite:
                </p>
                <p style={{color: 'darkslateblue'}}>
                    {link}
                </p>
            </Container>
        </Container>
    )

}

export default BetaTesters;
