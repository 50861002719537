import {Container} from "react-bootstrap";
import ScreenShot from "./ScreenShot";
import navigating from "../../assets/navigating.png";

const preface =
    ['Navigate within CircOut using the tabs located at the bottom of the screen. '];
const navigatingText = ['The Home screen also provides several short cuts for commonly used features. ',
    'Tap the Workouts tab to create and perform a new workout or perform an existing workout. ',
    'Tap the Settings tab to manage assets including the capability to view, add,  and delete workouts and exercises. '];

const Navigating = () => {
    return (
        <Container style={{marginTop: 32}}>
            <h3 style={{fontStyle: 'italic', color: 'darkslateblue'}}
                className={'text-center mt-1 mb-1'}>
                Navigating in CircOut
            </h3>
            <p style={{color: 'darkslateblue'}} className={'mt-3'}>{preface}</p>
            <ScreenShot text={navigatingText} image={navigating} />
        </Container>
    )
}

export default Navigating;
