import ScreenShot from "./ScreenShot";
import workout from '../../assets/workout.png';
import paused from '../../assets/paused.png';
import review from '../../assets/review.png';

const workoutText =
    ['Workout provides visual and audio information including current status and count-down timers. ' +
    'Status includes the current exercise when in the effort phase and next exercise when in the recovery phase. ' +
    'The time remaining in the workout is displayed at the top and the time remaining in each phase is displayed in the center. ' +
    'Periodic audio cues are provided sufficient to complete the workout without viewing the screen. ',
    'The screen can be locked by pressing and holding the lock icon. ' +
    'This will prevent accidentally interrupting the workout if the phone is handled. ' +
    'Press and hold the icon to release the lock'];
const pausedText =
    ['The workout can be temporarily suspended by tapping Pause. ' +
    'The duration and phase timers will be frozen and the counter will indicate the pause duration. ',
    'Pause is useful for creating additional recovery tine between exercises and/or circuits. ' +
    'Terminate the workout by tapping End werkout.'];
const reviewText =
    ['When the workout is completed or terminated, a review is displayed. ' +
    'the review indicates the relative total among effort, recovery, and paused times. ' +
    'An option to save the workout will be provided if the workout is newly defined. '];

const PeformingWorkout = () => {
    return (
        <div>
            <ScreenShot text={workoutText} image={workout} />
            <ScreenShot text={pausedText} image={paused} />
            <ScreenShot text={reviewText} image={review} />
        </div>
    )
}

export default PeformingWorkout;
