import {Col, Container, Image, Row} from "react-bootstrap";

const ScreenShot = ({text, image}) => {
    return (
        <Row style={{marginTop: 32}}>
            <Col xs={6} sm={5} md={4} lg={3} xl={3} xxl={3}>
                <Container style={{
                    marginBottom: 16
                }}>
                <Image
                    style={{marginLeft: '5%'}}
                    width={'90%'}
                    rounded={true}
                    src={image}
                    alt={'photo'}
                />
                </Container>
            </Col>
            <Col xs={12} sm={6} md={6} lg={7} xl={7} xxl={8}>
                <Container style={{marginRight: 8}}>
                {text.map((p, index) => (
                    <p key={index} style={{color: 'darkslateblue'}}>{p}</p>
                ))}
                </Container>
            </Col>
        </Row>
    )
}

export default ScreenShot;
