import {BrowserRouter, Outlet, Route, Routes} from "react-router-dom";
import Layout from './pages/Layout';
import Home from "./pages/Home";
import About from "./pages/About";
import Support from "./pages/Support";
import Products from "./pages/Products";
import Contact from "./pages/Contact";
import {Container, Nav, Navbar} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";
import logo from "./assets/prtlogo-trn.PNG";
import BetaTesters from "./pages/BetaTesters";
import Frame from "./pages/Frame";
import background from './assets/background.png';

const App = () => {
    console.log('host: ' + window.location.host);
    if (window.location.host.split('.')[0] === 'support') {
        return (
            <div style={{
                backgroundImage: `url(${background})`,
                backgroundSize: 'cover',
            }}>
            <BrowserRouter>
                <Routes>
                    <Route path={"/"} element={<Menu/>}>
                        <Route index element={<Support/>}/>
                        <Route path={'/Contact'} element={<Contact/>}/>
                    </Route>
                </Routes>
            </BrowserRouter>
            </div>
        );
    } else {
        return (
            <div style={{
                backgroundImage: `url(${background})`,
                backgroundSize: 'cover',
            }}>
            <BrowserRouter>
                <Routes>
                    <Route path={"/"} element={<Layout/>}>
                        <Route index element={<Home/>}/>
                        <Route path={'/About'} element={<About/>}/>
                        <Route path={'/Contact'} element={<Contact/>}/>
                        <Route path={'/Products'} element={<Products/>}/>
                        <Route path={'/Support'} element={<Support/>}/>
                        <Route path={'/BetaTesters'} element={<BetaTesters/>}/>
                        <Route path={'/Frame'} element={<Frame/>}/>
                    </Route>
                </Routes>
            </BrowserRouter>
            </div>
        );
    }
};

const Menu = () => {
    return (
        <>
            <Navbar
                style={{
                    backgroundColor: 'lightsteelblue',
                    color: 'darkslateblue',
                }}

                expand={"sm"}>
                <Container>
                    <Navbar.Brand style={{color: 'darkslateblue'}} href={"/"}>
                        <img
                            src={logo}
                            width={150}
                            alt={'Perform-RT'}
                        />
                    </Navbar.Brand>
                    <Nav className={"justify-content-end"}>
                        <LinkContainer to={"/Contact"} >
                            <Nav.Item>Contact</Nav.Item>
                        </LinkContainer>
                    </Nav>
                </Container>
            </Navbar>
            <Outlet />
        </>
    );


}



export default App;
