import {Container} from "react-bootstrap";
import ScreenShot from "./ScreenShot";
import workouts from "../../assets/workouts.png";
import PeformingWorkout from "./PeformingWorkout";
import SettingTiming from "./SettingTiming";
import selectingexercises from "../../assets/selectingexercises.png";

const preface =
    ['Use create workout to define and immediately perform a new workout. ' +
    'This feature supports ad-hoc workouts. ' +
    'Upon completion, an option is provided to save the workout.'];
const settingsText = ['Tap Workouts in the tab menu then Create workout.'];
const selectingexercisesText =
    ['Select existing exercises by tapping on the item in the Exercises list. ' +
    'The selected exercise with move into the Circuit list. ' +
    'Tap on the item in the Circuit list to remove the exercise. ' +
    'Exercises will be performed in the order they appear in the Circuit list. ' +
    'Tap clear to empty the circuit list and start over. ',
        'Add a new exercise by typing the name into the entry at the top of the page. ' +
        'The new exercise will be added to the Circuit list and will also be available for future use. ',
        'There is a three exercise minimum and an exercise can only be added once to a circuit. ',
        'Now is a good time to complete your warmup and prepare your workout space. ' +
        'Tap Start workout to begin the first exercise after a 15 second delay. '];

const CreatingWorkout = () => {
    return (
        <Container style={{marginTop: 32}}>
            <h3 style={{fontStyle: 'italic', color: 'darkslateblue'}} className={'text-center mt-1 mb-1'}>Creating a workout</h3>
            <p style={{color: 'darkslateblue'}} className={'mt-3'}>{preface}</p>
            <ScreenShot text={settingsText} image={workouts} />
            <SettingTiming />
            <ScreenShot text={selectingexercisesText} image={selectingexercises} />
            <PeformingWorkout />
        </Container>
    )
}

export default CreatingWorkout;
