import {Card, Container} from "react-bootstrap";

const message =
    'We welcome your questions, observations, and suggestions. ';
const email =
    'Email us at: support@perform-rt.com';
const Contact = () => {
        return (
                <Container
                    className={'vh-100'}
                    style={{
                        marginTop: 0,
                        borderRadius: 8,
                        paddingTop: 8,
                        paddingBottom: 16}}>
                    <Card style={{
                        backgroundColor: 'orange',
                        margin: 24,
                        }}>
                        <Card.Header as={'h5'}>Contact information</Card.Header>
                        <Card.Body style={{backgroundColor: 'aliceblue'}}>
                            <Card.Text>{message}</Card.Text>
                            <Card.Text>{email}</Card.Text>
                        </Card.Body>
                    </Card>
                </Container>
        )
}
export  default Contact

