import {Container} from "react-bootstrap";
import ScreenShot from "./ScreenShot";
import settings from "../../assets/settings.png";
import manageworkouts from "../../assets/manageworkouts.png";
import viewingworkout from "../../assets/viewingworkout.png";

const preface =
    ['Viewing a workout displays the timing and circuit for a selected workout. '];
const settingsText = ['Tap Settings in the tab menu then Manage workouts.'];
const manageworkoutsText = ['Tab one of the workouts in the list.']

const ViewingWorkout = () => {
    return (
        <Container style={{marginTop: 32}}>
            <h3 style={{fontStyle: 'italic', color: 'darkslateblue'}} className={'text-center mt-1 mb-1'}>Viewing workout details</h3>
            <p style={{color: 'darkslateblue'}} className={'mt-3'}>{preface}</p>
            <ScreenShot text={settingsText} image={settings} />
            <ScreenShot text={manageworkoutsText} image={manageworkouts} />
            <ScreenShot text={['Workout details are dispalyed.']} image={viewingworkout} />
        </Container>

    );
};

export default ViewingWorkout;
