import {Outlet} from 'react-router-dom';
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {LinkContainer} from 'react-router-bootstrap';
import logo from '../assets/prtlogo-trn.PNG';

const Layout = () => {
    return (
      <>
          <Navbar
              style={{
                  backgroundColor: 'lightskyblue',
                  color: 'darkslateblue',
              }}

              expand={"sm"}>
              <Container>
                  <Navbar.Brand style={{color: 'darkslateblue'}} href={"/"}>
                      <img
                          src={logo}
                          width={150}
                          alt={'Perform-RT'}
                          />
                  </Navbar.Brand>
                  <Navbar.Toggle aria-controls={"basic-navbar-nv"} />
                  <Navbar.Collapse id={"basic-navbar-nav"}>
                      <Nav className={"sm-auto"}>
                          <LinkContainer to={"/Products"} >
                              <Nav.Link>Products</Nav.Link>
                          </LinkContainer>
                          <LinkContainer to={"/Support"} >
                              <Nav.Link>Support</Nav.Link>
                          </LinkContainer>
                          <NavDropdown title={'About us'}>
                              <LinkContainer to={"/About"} >
                                  <NavDropdown.Item>Company</NavDropdown.Item>
                              </LinkContainer>
                              <LinkContainer to={"/Contact"} >
                                  <NavDropdown.Item>Contact</NavDropdown.Item>
                              </LinkContainer>
                          </NavDropdown>
                      </Nav>
                  </Navbar.Collapse>
              </Container>
          </Navbar>
          <Outlet />
      </>
    );
}

export default Layout;
