import {Container} from "react-bootstrap";

const whatis =
    'Circuit training is a well-established exercise regimen that is highly effective ' +
    'and efficient. A circuit consists of an ordered sequence of exercises ' +
    'that include cardio and resistance elements. Resistance exercises are ' +
    'chosen to target various muscle groups. The effectiveness of ' +
    'circuit training derives from maintaining an elevated heart rate while building ' +
    'muscular strength. Alternating among different muscle groups reduces ' +
    'recovery time between exercises, therefore, increasing time efficiency. ' +
    'As a result, an effective circuit training ' +
    'workout can be completed in as little as 20 minutes.'
const recommendations = [
    'Research proper technique and practice new exercises.',
    'Find exercises that challenge your individual strengths and limitations.',
    'Consult a physician if you have health concerns prior to starting any exercise program.',
    'IF IT HURTS, DONT DO IT!',
];
const practices = [
    'Engage your core by tightening your abdominal muscles and pulling your belly button in ' +
    'toward your spine.',
    'Breath consistently and in a controlled manner during and between exercises.',
    'Maintain good posture by keeping your chin up and shoulders down and back.',
];
const workout =
    'A workout is managed by a set of timing parameters and a sequence of exercises that comprise a circuit. ' +
    'These timing parameters include the following:';
const duration =
    'Duration is the length of the workout. ' +
    'The circuit is repeated until the duration expires. ';
const effort =
    'Effort is the active phase of an exercise.';
const recovery =
    'Recovery is the inactive phase between exercises.';
const pacing =
    'Pacing is an important concept in circuit training. ' +
    'The approximate number of repetitions completed during the effort phase of an exercise determines the pace. ' +
    'The goal is to complete the entire workout, therefore, it is important to establish a pace that is sustainable. ' +
    'As you progress, the pace can quicken, effort can increase, and/or recovery can decrease. ';

const CircuitTraining = () => {
    return (
        <Container>
            <h5 className={'text-center mt-5'} style={{color: 'darkslateblue'}}>About circuit training</h5>
            <p style={{color: 'darkslateblue'}}>{whatis}</p>
            <h5 style={{color: 'darkslateblue'}}>Recommendations</h5>
            <li style={{color: 'darkslateblue'}}>{recommendations[0]}</li>
            <li style={{color: 'darkslateblue'}}>{recommendations[1]}</li>
            <li style={{color: 'darkslateblue'}}>{recommendations[2]}</li>
            <li style={{color: 'darkslateblue'}}>{recommendations[3]}</li>
            <h5 className={'mt-3'} style={{color: 'darkslateblue'}}>Good practices</h5>
            <li style={{color: 'darkslateblue'}}>{practices[0]}</li>
            <li style={{color: 'darkslateblue'}}>{practices[1]}</li>
            <li style={{color: 'darkslateblue'}}>{practices[2]}</li>
            <h5 className={'mt-3'} style={{color: 'darkslateblue'}}>Workout</h5>
            <p style={{color: 'darkslateblue'}}>{workout}</p>
            <li style={{color: 'darkslateblue'}}>{duration}</li>
            <li style={{color: 'darkslateblue'}}>{effort}</li>
            <li style={{color: 'darkslateblue'}}>{recovery}</li>
            <p className={'mt-3'} style={{color: 'darkslateblue'}}>{pacing}</p>

        </Container>
    )
}

export default CircuitTraining;
