import {Container} from "react-bootstrap";

const Frame = () => {
   return (
       <Container>
           <iframe
               title={'Install CircOut-lite'}
               style={{height: '100vh', width: '100%'}}
               src={'https://testflight.apple.com/join/gz18xFHd'}></iframe>
       </Container>
   )
}
export default Frame;
