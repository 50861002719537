import {Container} from "react-bootstrap";
const intro = 'Perform-RT is a self-funded startup dedicated to the proposition that fitness through exercise can be simple, ' +
    'engaging, and efficient. On that basis, we are focussed on delivering easy-to-use apps that are both time and cost ' +
    'efficient. ' +
    'Most importantly, we want to provide a consistent and enjoyable experience that promotes a healthier you. ';
const about =
    'Company founder Robert Barkley is a retired engineer who in the midst of the covid pandemic found' +
    ' himself physically ill-prepared. ' +
    'Weighing his options, he decided to get into the gym for some badly needed exercise. ' +
    'It was there he discovered that regular exercise need not be tedious nor excessively time-consuming. ' +
    'A simple circuit-training program with relatively short bursts of high-intensity ' +
    'effort and minimal recovery could produce results in as little as 20 minutes per session. ';
const results =
    'After a few months, the benefits became obvious. ' +
    'Climbing stairs, hiking up hill, and carrying heavy objects no longer produced shortness of breath. ' +
    'Insulin usage steadily decreased while body weight gradually decreased. ' +
    'Most importantly, each workout passed remarkably quickly and ended with a genuine sense of accomplishment.'
const action =
    'Using his software engineering experience, Robert began developing an app for his own use that would ' +
    'facilitate a consistent workout at home, while traveling, or in the gym. ' +
    'Using his chemical engineering training, Robert began researching the physiology of exercise specifically ' +
    'related to circuit training and his positive experience. ' +
    'Finally, work began to transition and expand upon this initiative for the benefit of those seeking similar results.';

const About = () => {
    return (
        <Container
            className={'min-vh-100 min-vw-100'}>
            <Container
                style={{backgroundColor: "aliceblue",
                    marginTop: 24,
                    borderRadius: 8,
                    paddingTop: 16,
                    paddingBottom: 16}}>
                <h3 style={{color: 'darkslateblue'}} className={'text-center'}>Company</h3>
                <p style={{color: 'darkslateblue'}}>{intro}</p>
                <p style={{color: 'darkslateblue'}}>{about}</p>
                <p style={{color: 'darkslateblue'}}>{results}</p>
                <p style={{color: 'darkslateblue'}}>{action}</p>
            </Container>
        </Container>
    )
}

export default About;
