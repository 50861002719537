import {Container} from "react-bootstrap";
import CircOutLt from "./Products/CircOutLt";

const Products = () => {
    return (
        <Container
            className={'min-vh-100 min-vw-100'}>
            <Container
                style={{backgroundColor: "aliceblue",
                    marginTop: 24,
                    borderRadius: 8,
                    paddingTop: 16,
                    paddingBottom: 16}}>
                <CircOutLt />
            </Container>
        </Container>
    )
}

export default Products;
