import {Col, Container, Image, Row} from "react-bootstrap";
import settiming from '../../assets/settingtiming.png';
import definecircuit from '../../assets/selectingexercises.png'
import performworkout from '../../assets/workout.png';
import reviewworkout from '../../assets/review.png';
import previewworkout from '../../assets/preview.png';
import instructexercise from '../../assets/viewingexercise.png';
import deleteitem from '../../assets/swiperemove.png';
import homeScreen from '../../assets/navigating.png'

const CircOutLt = () => {

    const preface =
        ['CircOut-lite is an easy-to-use, entry-level training app well suited to individuals ' +
        'of any age group and fitness level. ' +
        'CircOut-lite delivers a consistent workout experience and requires no specialized ' +
        'equipment nor facilities. ' +
        'Create and perform workouts anywhere at anytime. '];
    const features = [
        'Create and perform workouts on-the-fly.',
        'Build and maintain a library of workouts and exercises.',
        'Adjust timing to meet your goals and abilities.',
        'Receive easy to follow visual and audio cues during workouts.',
        'Use predefined exercises or add your own favorites.',
    ];
    const samples = 'The following sample screens illustrate the operation of CircOut-lite.';
    const defineworkouts = 'Workouts are defined by setting timing parameters and building a circuit.';
    const performworkouts = 'Workouts are performed using easy to follow visual and audio directions. ' +
        'Upon completion, a summary of activity is provided.';
    const manageworkouts = 'A variety of features are provided to inform, instruct, and manage workouts and exercises.';

    return (
        <Container>
            <Row>
                <Col xs={12} sm={12} md={11} lg={9} xl={9} xxl={2}>
            <h3
                style={{fontStyle: 'italic', color: 'darkslateblue'}}
                className={'text-center'}>
                CircOut-lite
            </h3>
                </Col>
            </Row>
            <Row style={{marginTop: 16}}>
                <Col xs={12} sm={5} md={6} lg={6} xl={6} xxl={6}>
                    <p style={{color: 'darkslateblue'}}>{preface}</p>
                </Col>
                <Col xs={4} sm={6} md={6} lg={3} xl={3} xxl={2} style={{width: 200, marginRight: 16, marginBottom: 16}}>
                    <Image
                        width={'90%'}
                        rounded={true}
                        src={homeScreen}
                        alt={'photo'}
                    />
                </Col>
            </Row>
            <h5
                style={{color: 'darkslateblue'}}
                className={'mt-1 mb-1'}>
                Features
            </h5>
            <div>
            {features.map((f, index) => (
                <li style={{color: 'darkslateblue'}} key={index}>{f}</li>
            ))}
            </div>
            <h5
                style={{color: 'darkslateblue'}}
                className={'mt-3 mb-3'}>
                Sample screens
            </h5>
            <p
                className={'mt-3'}
                style={{color:'darkslateblue'}}>
                {samples}
            </p>
            <p
                className={'mt-3'}
                style={{color:'darkslateblue'}}>
                {defineworkouts}
            </p>
            <Container>
                <Row>
                    <Col>
                        <Image
                            style={{marginRight: 16, marginBottom: 16}}
                            width={200}
                            rounded={true}
                            src={settiming}
                            alt={'image'}
                        />
                        <Image
                            style={{marginRight: 16, marginBottom: 16}}
                            width={200}
                            rounded={true}
                            src={definecircuit}
                            alt={'image'}
                        />
                    </Col>
                </Row>
            </Container>
            <p
                className={'mt-3'}
                style={{color:'darkslateblue'}}>
                {performworkouts}
            </p>
            <Container>
                <Row>
                    <Col>
                        <Image
                            style={{marginRight: 16, marginBottom: 16}}
                            width={200}
                            rounded={true}
                            src={performworkout}
                            alt={'image'}
                        />
                        <Image
                            style={{marginRight: 16, marginBottom: 16}}
                            width={200}
                            rounded={true}
                            src={reviewworkout}
                            alt={'image'}
                        />
                    </Col>
                </Row>
            </Container>
            <p
                className={'mt-3'}
                style={{color:'darkslateblue'}}>
                {manageworkouts}
            </p>
            <Container>
                <Row>
                    <Col>
                        <Image
                            style={{marginRight: 16, marginBottom: 16}}
                            width={200}
                            rounded={true}
                            src={previewworkout}
                            alt={'image'}
                        />
                        <Image
                            style={{marginRight: 16, marginBottom: 16}}
                            width={200}
                            rounded={true}
                            src={instructexercise}
                            alt={'image'}
                        />
                        <Image
                            style={{marginRight: 16, marginBottom: 16}}
                            width={200}
                            rounded={true}
                            src={deleteitem}
                            alt={'image'}
                        />
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}

export default CircOutLt;
