import {Container} from "react-bootstrap";
import workouts from "../../assets/workouts.png";
import myworkouts from "../../assets/myworkouts.png";
import ScreenShot from "./ScreenShot";
import preview from "../../assets/preview.png";
import PeformingWorkout from "./PeformingWorkout";

const preface =
    'My workouts is used to preview and start either a sample or previously saved workout.';
const settingsText = ['Tap Workouts in the tab menu then My workouts.'];
const myworkoutsText = ['Tap to select a workout from the list.'];
const previewText = ['A preview of the selected workout is displayed including timing and the circuit. ' +
    'Now is a good time to complete your warm-up and organize your workout space. ' +
    'The first exercise will begin 15 seconds after tapping Start workout.'];

const SelectingWorkout = () => {
    return (
        <Container style={{marginTop: 32}}>
            <h3 style={{fontStyle: 'italic', color: 'darkslateblue'}} className={'text-center'}>Performing a workout</h3>
            <p style={{color: 'darkslateblue'}}>{preface}</p>
            <ScreenShot text={settingsText} image={workouts} />
            <ScreenShot text={myworkoutsText} image={myworkouts} />
            <ScreenShot text={previewText} image={preview} />
            <PeformingWorkout />
        </Container>
    )
}
export default SelectingWorkout;
