import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import CircuitTraining from "./Support/CircuitTraining";
import SelectingWorkout from "./Support/SelectingWorkout";
import CreatingWorkout from "./Support/CreatingWorkout";
import AddingWorkout from "./Support/AddingWorkout";
import ViewingWorkout from "./Support/ViewingWorkout";
import ViewingExercise from "./Support/ViewingExercise";
import RemovingItems from "./Support/RemovingItems";
import {Element, scroller} from 'react-scroll';
import AddingExercises from "./Support/AddingExercises";
import Navigating from "./Support/Navigating";

const ReturnToTop = () => (
    <p
        className={'text-center mt-3'}
        style={{color: 'steelblue'}}
        onClick={() => scroller.scrollTo('topofpage')}>
        return to topics
    </p>
)

const Support = () => {

    return (
        <Element id={'topofpage'}>
        <Container
            className={'min-vh-100 min-vw-100'}>
            <Container
                style={{backgroundColor: "aliceblue",
                    marginTop: 24,
                    borderRadius: 8,
                    paddingTop: 16,
                    paddingBottom: 16}}>
                <Container>
                <Row>
                    <h3 className={'text-center'} style={{color: 'darkslateblue'}}>Topics</h3>
                </Row>
                <Row xs={1} md={2} lg={3}>
                    <Col>
                        <li
                            style={{color: 'steelblue'}}
                            onClick={() => scroller.scrollTo('circuittraining')}>
                            About circuit training
                        </li>
                    </Col>
                    <Col>
                        <li
                            style={{color: 'steelblue'}}
                            onClick={() => scroller.scrollTo('navigating')}>
                            Navigating in CircOut
                        </li>
                    </Col>
                    <Col>
                        <li
                            style={{color: 'steelblue'}}
                            onClick={() => scroller.scrollTo('selectingworkout')}>
                            Performing a workout
                        </li>
                    </Col>
                    <Col>
                        <li
                            style={{color: 'steelblue'}}
                            onClick={() => scroller.scrollTo('creatingworkout')}>
                            Creating a workout
                        </li>
                    </Col>
                    <Col>
                        <li
                            style={{color: 'steelblue'}}
                            onClick={() => scroller.scrollTo('addingworkout')}>
                            Adding workouts
                        </li>
                    </Col>
                    <Col>
                        <li
                            style={{color: 'steelblue'}}
                            onClick={() => scroller.scrollTo('addingexercises')}>
                            Adding exercises
                        </li>
                    </Col>
                    <Col>
                        <li
                            style={{color: 'steelblue'}}
                            onClick={() => scroller.scrollTo('viewingworkout')}>
                            Viewing workout details
                        </li>
                    </Col>
                    <Col>
                        <li
                            style={{color: 'steelblue'}}
                            onClick={() => scroller.scrollTo('viewingexercise')}>
                            Viewing exercise instructions
                        </li>
                    </Col>
                    <Col>
                        <li
                            style={{color: 'steelblue'}}
                            onClick={() => scroller.scrollTo('removingitem')}>
                            Removing workouts and exercises
                        </li>
                    </Col>
                </Row>
                </Container>
                <Element id={'circuittraining'}>
                    <CircuitTraining />
                    <ReturnToTop />
                </Element>
                <Element id={'navigating'}>
                    <Navigating />
                    <ReturnToTop />
                </Element>
                <Element id={'selectingworkout'}>
                    <SelectingWorkout />
                    <ReturnToTop />
                </Element>
                <Element id={'creatingworkout'}>
                    <CreatingWorkout />
                    <ReturnToTop />
                </Element>
                <Element id={'addingworkout'}>
                    <AddingWorkout />
                    <ReturnToTop />
                </Element>
                <Element id={'addingexercises'}>
                    <AddingExercises />
                    <ReturnToTop />
                </Element>
                <Element id={'viewingworkout'}>
                    <ViewingWorkout />
                    <ReturnToTop />
                </Element>
                <Element id={'viewingexercise'}>
                    <ViewingExercise />
                    <ReturnToTop />
                </Element>
                <Element id={'removingitem'}>
                    <RemovingItems />
                    <ReturnToTop />
                </Element>
            </Container>
        </Container>
        </Element>

    )
}

export default Support;
